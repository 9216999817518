import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, FormGroup} from "reactstrap";
import {getAuth, multiFactor, sendEmailVerification, TotpMultiFactorGenerator} from "firebase/auth";
import {envName, isProduction} from "../../../utils/environment";
import QRCode from "qrcode";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";

function MFAEnrollmentForm({dispatch, user}) {

    const [showVerification, setShowVerification] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [totpSecret, setTotpSecret] = useState(null);
    const [otp, setOtp] = useState("");

    useEffect(() => {
        activate2FA();
    }, [])

    const activate2FA = () => {
        if (getAuth().currentUser == null) {
            return;
        }

        multiFactor(getAuth().currentUser)
            .getSession()
            .then((multiFactorSession) => {
                TotpMultiFactorGenerator.generateSecret(multiFactorSession)
                    .then((ts) => {
                        setTotpSecret(ts);
                        const totpIssuerName = isProduction() ? 'CB Store Manager' : 'CB Store Manager-' + envName().substring(0, 3);
                        const totpUri = ts.generateQrCodeUrl(getAuth().currentUser.email, totpIssuerName);
                        setShowVerification(true);
                        setInitialized(true);
                        setTimeout(() => {
                            QRCode.toCanvas(canvas, totpUri)
                                .then((res) => {

                                })
                                .catch((e) => {
                                    console.log(e);
                                });
                        }, 0)

                    })
                    .catch((e) => {
                        if (e.code == 'auth/unverified-email') {
                            sendEmailVerification(getAuth().currentUser, {url: window.location.href});
                            setInitialized(true);
                        } else if (e.code == 'auth/maximum-second-factor-count-exceeded') {
                            dispatch({
                                type: 'USER_ENROLMENT_FAILURE',
                                payload: 'This account is already enrolled in 2FA.',
                            });
                        } else if (e.code == 'auth/requires-recent-login') {
                            dispatch({
                                type: 'USER_ENROLMENT_FAILURE',
                                payload: 'Re-authentication required to be enrolled in 2FA.',
                            });
                        } else {
                            dispatch({
                                type: 'USER_ENROLMENT_FAILURE',
                                payload: e.message,
                            });
                        }
                    });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const verify2FA = () => {
        if (otp == '') {
            dispatch({
                type: 'USER_ENROLMENT_FAILURE',
                payload: 'Verification Code is required.',
            });
            return;
        }
        const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(totpSecret, otp);
        multiFactor(getAuth().currentUser)
            .enroll(multiFactorAssertion, getAuth().currentUser.displayName)
            .then((res) => {
                dispatch({
                    type: 'USER_ENROLMENT_SUCCESS',
                });
            })
            .catch((e) => {
                var errMsg = e.message;
                if (e.code == 'auth/invalid-verification-code') {
                    errMsg = 'Your 2FA Verification Code is incorrect or has expired';
                }
                dispatch({
                    type: 'USER_ENROLMENT_FAILURE',
                    payload: errMsg,
                });
            });
    }


    return !initialized ? null : <div style={{paddingTop: '1.5rem'}}>
        {user.showEnrollmentSuccessMessage ?
            <Alert color="success">Successfully enrolled for 2FA, please log in.</Alert> : (
                <>
                    <h4 style={{paddingTop: '0.5rem'}}>Set Up Two-Factor Authentication</h4>
                    <FormGroup row>
                        {!showVerification ? <div>
                            <h5>Step 1: Verify your email</h5>
                            <p className="mb-2">A verification email has been sent to your inbox. Please click the link in the email to
                                proceed.</p>
                            <p>Didn't receive the email?</p>
                            <Button type="button" color="primary" className="me-2" onClick={() => activate2FA()}>
                                Resend email
                            </Button>
                        </div> : <div>
                            <h5 style={{paddingTop: '0.5rem'}}>Step 2: Set Up TOTP</h5>
                            <p className="mb-2">Scan this QR code below using your authenticator app (e.g. Google Authenticator or
                                Authy).</p>
                            <p>Once scanned, enter the 6-digit code generated by the app to continue.</p>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}>
                                <label className={"me-2"}>Enter code</label>
                                <input id="otp" className={"me-2"} onChange={(e) => setOtp(e.target.value)}/>
                                <Button type="button" color="primary" className="me-2" onClick={() => verify2FA()}>
                                    Verify Code
                                </Button>
                            </div>
                        </div>}
                    </FormGroup>
                    {showVerification ? (
                        <FormGroup row>
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <canvas id="canvas"></canvas>
                            </div>
                        </FormGroup>) : null}
                </>)}
    </div>;
}

export default reduxForm({
    form: 'MFAEnrollmentFormForm',
    enableReinitialize: true,
})(
    connect((state) => ({
        user: state.user,
    }))(MFAEnrollmentForm)
);
