import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  InputGroup,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as userActions from '../../../actions/userActions';
import * as operatorActions from '../../../actions/operatorActions';
import * as dataCentreActions from '../../../actions/dataCentreActions';
import MFAEnrollmentForm from "./MFAEnrollmentForm";
import { getAuth } from 'firebase/auth';
import moment from 'moment/moment';
import { StoreManagerApi } from '../../../api';

function LoginForm({ user, handleSubmit, dispatch }) {
  const navigate = useNavigate();
  const [otpModal, setOtpModal] = useState(false);
  const [otp, setOtp] = useState();
  const [resolverIdentity, setResolverIdentity] = useState();
  const [loginForm, setLoginForm] = useState();

  function submit(form) {
    form = {
      ...form,
      grant_type: 'password',
    };
    dispatch(userActions.logIn(form))
      .then((response) => {
          StoreManagerApi.getFBConfig().then(res=>{
            const loginTime = localStorage.getItem('lastLoginTime-'+form.username);
            const refreshValiditySeconds = parseInt(res.refreshTokenValidity);
            let isExpired = true;
              if (loginTime) {
                const time = parseInt(loginTime);
                isExpired = moment().isAfter(moment(time).add(refreshValiditySeconds, 'seconds'));
              }
            setLoginForm(form);
            if (isExpired && response.hints) {
              setResolverIdentity(response);
              setOtpModal(true);
            } else {
              getAuth().currentUser.getIdToken().then(idToken => {
                logAuth2In({...form, ipAccessToken: idToken});
              });
            }
          });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function logAuth2In(form) {
    dispatch(userActions.logAuth2In(form))
      .then((response) => {
        dispatch(operatorActions.getOne(response.user_id)).then((user) => {
          dispatch(userActions.select(user));
          dispatch(dataCentreActions.select(user.dataCentre));
          navigate('/');
          localStorage.setItem('lastLoginTime-'+user.username, Date.now().toString());
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function verifyToken() {
    dispatch(userActions.verifyToken(otp, resolverIdentity))
      .then((res) => {
        logAuth2In({...loginForm, ipAccessToken: res.user.accessToken} );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function toggleClone() {
    setOtpModal(!otpModal);
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      verifyToken();
    }
  }

  return (
    <form onSubmit={handleSubmit(submit)} className="form-horizontal">
      <Row className="justify-content-center">
        <Col md="6">
          <Card className="p-4">
            <CardBody>
              <h2 style={{ textAlign: 'center' }}>Store Manager</h2>
              <p className="mb-4 text-muted" style={{ textAlign: 'center' }}>
                Sign In to your account
              </p>
              <InputGroup className="mb-3">
                <InputGroupText>
                  <i className="icon-user" />
                </InputGroupText>
                <Field
                  name="username"
                  component="input"
                  type="text"
                  placeholder="Username"
                  className="form-control"
                  required
                />
              </InputGroup>
              <InputGroup className="mb-4">
                <InputGroupText>
                  <i className="icon-lock" />
                </InputGroupText>
                <Field
                  name="password"
                  component="input"
                  type="password"
                  placeholder="Password"
                  className="form-control"
                  required
                />
              </InputGroup>
              {user.error ? (
                <Row>
                  <Col xs="12">
                    <div className="alert alert-danger">
                      <strong>Error: </strong>
                      {user.error.message}
                    </div>
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Col xs="6">
                  <Button type="submit" color="primary" className="px-4">
                    Login
                  </Button>
                </Col>
                <Col xs="6" className="d-flex flex-row justify-content-end align-items-center">
                  <Button color="link" className="px-0" disabled>
                    Forgot password?
                  </Button>
                </Col>
              </Row>
              {user.showMfaForm ? (
                  <Row>
                    <Col xs="12">
                      <MFAEnrollmentForm />
                    </Col>
                  </Row>
              ) : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={otpModal} toggle={toggleClone} className="lg" style={{ minWidth: '60%' }}>
        <ModalHeader toggle={toggleClone}>One-time Password Code</ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Col md="4">
              <label>Please enter One-time Password Code.</label>
            </Col>
            <Col md="4">
              <Field
                className="form-control"
                component="input"
                onChange={(e) => setOtp(e.target.value)}
                onKeyDown={handleKeyDown}
              ></Field>
            </Col>
            <Col md="4">
              <Button type="button" color="primary" className="px-4" onClick={verifyToken}>
                Verify
              </Button>
            </Col>
          </FormGroup>
          {user.error ? (
            <Row>
              <Col xs="12">
                <div className="alert alert-danger">
                  <strong>Error: </strong>
                  {user.error.message}
                </div>
              </Col>
            </Row>
          ) : null}
        </ModalBody>
      </Modal>
    </form>
  );
}

export default reduxForm({
  form: 'loginForm',
  enableReinitialize: true,
})(
  connect((state) => ({
    user: state.user,
    resolver: state.resolver,
    platform: state.platform,
  }))(LoginForm)
);
